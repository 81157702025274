import styled from "styled-components";
import { Container, Section, Heading } from "../../sdk/Layout";

export const Wrapper = styled(Section)`
    background-color: #112c55;
    box-shadow: inset 0 -2000px 0 rgba(17, 44, 85, 0.95);
    background-image: url(${(props) =>
        props.img
            ? props.img
            : "https://www.noritex.com/gFxLiy5TobeEM45Re1400x633-santini.jpg"});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    position: relative;
    ${Heading} {
        h2,
        h3 {
            color: #fff;
            &:before {
                background-color: #fff;
            }
        }
    }
`;

export const QuoteL = styled.div`
    right: 100%;
    top: 0;
    @media (max-width: 768px) {
        left: 0;
        right: initial;
    }
`;
export const QuoteR = styled.div`
    left: 100%;
    bottom: 0;
    @media (max-width: 768px) {
        right: 0;
        left: initial;
    }
`;

export const Opinions = styled.div`
    text-align: center;
    max-width: 550px;
    margin: auto;
    position: relative;
    padding: 0px 30px;
    & ${QuoteL}, & ${QuoteR} {
        position: absolute;
        opacity: 0.2;
        svg {
            color: white;
            width: 40px;
            @media (max-width: 768px) {
                width: 20px;
            }
        }
    }
`;

export const Opinion = styled.div`
    text-align: left;
    padding: 0;
    margin: 0;
    display: inline-block;
`;

export const Text = styled.div`
    p {
        margin: 0;
        padding: 0;
        font-size: 20px;
        opacity: 0.6;
        font-style: italic;
        color: #fff;
        letter-spacing: 0;
        line-height: 26px;
        + p {
            margin-top: 15px;
        }
    }

    @media (max-width: 768px) {
        p {
            font-size: 12px;
            line-height: 18px;
        }
    }
`;

export const By = styled.span`
    display: block;
    padding-top: 15px;
    font-size: 12px;
    color: #fff;
    font-weight: bold;
`;

export const Pagination = styled.ul`
    margin: 0;
    padding: 0;
    margin: auto;
    font-size: 0;
    max-width: 200px;
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    gap: 5px;
    align-items: bottom;
    span {
        cursor: pointer;
        display: block;
        height: 38px;
        border-bottom: solid 1px white;
        padding: 20px 0;
        background-color: transparent;
        width: 100%;
        transition: all 200ms linear;
        opacity: 0.2;
        &.current {
            opacity: 1;
        }
    }
`;

export const Navigation = styled.div`
    position: absolute;
    padding: 0px 20px;
    left: 0;
    top: 50%;
    margin-top: -19px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        border-radius: 100%;
        transition: all 200ms linear;
        opacity: 0.8;
        &:hover:not(.disabled) {
            background-color: rgba(255, 255, 255, 0.1);
            cursor: pointer;
            opacity: 1;
        }
        &.disabled {
            opacity: 0.2;
        }
    }
    svg {
        color: white;
        width: 15px;
    }

    @media (max-width: 768px) {
        display: none;
    }
`;
