import React from "react";
import styled from "styled-components";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { Button } from "../sdk/Button";
import { Container, Section } from "../sdk/Layout";
import InternalLink from "./InternalLink";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SliderWrapper = styled.ul`
    position: relative;
    > div {
        /* overflow: hidden; */
    }
    .react-swipeable-view-container {
        > div {
            /* overflow: hidden; */
        }
    }
    li {
        overflow: hidden;
    }
`;

const SliderItem = styled.li`
    height: 400px;
    position: relative;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        // opacity: 0.8;
    }
    ${Section} {
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        transform: translateY(-50%);

        h1 {
            font-size: 50px;
        }
        p {
            font-size: 20px;
            margin-top: 20px;
            max-width: 400px;
        }
		h1, p {
			color: white;
			text-shadow: 2px 2px 4px black;
		}
    }
    ${Container} {
        padding-bottom: 30px;
    }

    @media (max-width: 768px) {
        height: 300px;
        ${Section} {
            h1 {
                font-size: 30px;
            }
            p {
                font-size: 18px;
            }
        }
    }
`;

const Pagination = styled.ul`
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    li {
        width: 14px;
        height: 14px;
        border-radius: 100%;
        background: transparent;
        border: 2px solid #fff;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        margin: 5px 10px;
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;
        &.current {
            background-color: #fff;
        }
    }
`;

const ButtonExtended = styled(Button)`
    height: 50px;
    padding-right: 50px;
    padding-left: 50px;
    border: none;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    margin-top: 30px;
`;

function SlideComponent({ slide, i }) {
    const {
        align,
        href: link,
        cta: text,
        p: content,
        img: image,
        h1: title,
        alt,
    } = slide;

    const imageSrc =
        (image && image.img && image.img.length > 0 && image.img[0]) || null;
    const thumb =
        (image && image.thumbs && image.thumbs.length > 0 && image.thumbs[0]) ||
        "";

    return (
        <SliderItem key={`slider-slide-A-${i}`}>
            <img
                src={imageSrc}
                alt={alt}
                srcSet={`
                ${thumb} 224w,
                ${imageSrc}?d=400x400 400w,
                ${imageSrc}?d=600x600 600w,
                ${imageSrc}?d=700x700 700w,
                ${imageSrc}?d=900x900 900w,
                ${imageSrc} 1200w
                `}
            />
            <Section>
                <Container>
                    <h1>{title}</h1>
                    <p>{content}</p>
                    {link && text ? (
                        <InternalLink {...link}>
                            <ButtonExtended white>{text}</ButtonExtended>
                        </InternalLink>
                    ) : null}
                </Container>
            </Section>
        </SliderItem>
    );
}

const renderPagination = (activeIndex, count, onClick, slides) => {
    const dots = [];

    if (count === 1) return null;

    slides.map((slide, i) => {
        dots.push(
            <li
                key={`slider-pagination-dot-${i}`}
                className={activeIndex === i ? "current" : null}
                onClick={() => onClick(i)}
            />
        );
    });

    return <Pagination>{dots}</Pagination>;
};

const Slider = ({ config }) => {
    if (config == null || config.length <= 0) return null;

    const [activeItemIndex, setActiveItemIndex] = React.useState(0);

    const changeActiveItem = (activeIndex) => {
        setActiveItemIndex(activeIndex);
    };

    const renderSlides = (slides) => {
        const slid = [];
        slides.map((slide, i) => {
            const sld = SlideComponent({ slide, i });
            if (sld != null) slid.push(sld);
        });

        return slid;
    };

    return (
        <SliderWrapper>
            <AutoPlaySwipeableViews
                enableMouseEvents
                interval={6000}
                index={activeItemIndex}
                onChangeIndex={changeActiveItem}
            >
                {renderSlides(config, activeItemIndex)}
            </AutoPlaySwipeableViews>
            {renderPagination(
                activeItemIndex,
                config.length,
                changeActiveItem,
                config
            )}
        </SliderWrapper>
    );
};

export default Slider;
