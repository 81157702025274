/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import InternalLink from "../InternalLink";
import Icons from "../Icons2/index";
import { Container, Section } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import {
	Title, SubTitle, SliderWrapper, Slide, Pagination,
} from "./style";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function SlideComponent({ slide }) {
	const {
		title, subtitle, paragraph, img, alt, cta, link,
	} = slide;

	return (
		<Slide>
			<picture>
				<img src={img?.img} alt={alt} />
			</picture>
			<div className="card">
				<h3>{title}</h3>
				<h4>{subtitle}</h4>
				<p>{paragraph}</p>
				{
					(link && cta) && (
						<InternalLink to={link}>
							<Button white small>{cta}</Button>
						</InternalLink>
					)
				}
			</div>
		</Slide>
	);
}

function SliderPagination({ slides, activeItemIndex, onChange }) {
	const handleClick = (e, index) => {
		e.preventDefault();
		onChange(index);
	};

	return (
		<Pagination>
			{slides.map((slide, i) => (
				<li
					key={`slider-pages-dot-${i}`}
					className={activeItemIndex === i ? "current" : null}
				>
					<a href="##" role="button" onClick={(e) => handleClick(e, i)}>●</a>
				</li>
			))}
		</Pagination>
	);
}

function SliderNew({ config }) {
	if (!config?.length || !config[0].slides?.length) return null;

	const [activeItemIndex, setActiveItemIndex] = React.useState(0);

	const handleChangeActiveItem = (activeIndex) => {
		setActiveItemIndex(activeIndex);
	};

	const handlePrevNext = (e, index) => {
		e.preventDefault();
		const max = config[0].slides.length;

		let newIndex = activeItemIndex + index;
		if (newIndex < 0) newIndex = max - 1;
		if (newIndex >= max) newIndex = 0;

		setActiveItemIndex(newIndex);
	};

	const { title, subtitle, slides } = config[0];

	return (
		<Section>
			<Container>
				<SliderWrapper>
					<Title>{title}</Title>
					<SubTitle isVisible={!!subtitle}>{subtitle}</SubTitle>
					<AutoPlaySwipeableViews
						enableMouseEvents
						interval={12000}
						index={activeItemIndex}
						onChangeIndex={handleChangeActiveItem}
					>
						{slides.map((slide, i) => (
							<SlideComponent slide={slide} key={`slider-slide-new-${i}`} />
						))}
					</AutoPlaySwipeableViews>
					<SliderPagination
						slides={slides}
						activeItemIndex={activeItemIndex}
						onChange={handleChangeActiveItem}
					/>
					<a href="##" role="button" className="indicator prev" onClick={(e) => handlePrevNext(e, -1)}>
						<Icons icon="chevron-left" />
					</a>
					<a href="##" role="button" className="indicator next" onClick={(e) => handlePrevNext(e, 1)}>
						<Icons icon="chevron-right" />
					</a>
				</SliderWrapper>
			</Container>
		</Section>
	);
}

export default SliderNew;
