/* eslint-disable object-curly-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import InternalLink from "../InternalLink";
import Icons from "../Icons2/index";
import { Container } from "../../sdk/Layout";
import { Button } from "../../sdk/Button";
import { Section, Title, SliderWrapper, Slide, FairsList, Pagination } from "./style";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function FairsListInfo({ fairs }) {
	return (
		<FairsList>
			{fairs.map((f, i) => (
				<li key={`fair-info-${i}`}>
					<h2>{f.fairTitle}</h2>
					<h3>
						<Icons icon="calendar" />
						<span>{f.fairDescription}</span>
					</h3>
				</li>
			))}
		</FairsList>
	);
}

function SlidesComponent({ slides, activeIndex, setActiveItemIndex }) {
	const handleChange = (index) => {
		setActiveItemIndex(index);
	};

	const handlePrevNext = (e, index) => {
		e.preventDefault();
		const max = slides.length;

		let newIndex = activeIndex + index;
		if (newIndex < 0) newIndex = max - 1;
		if (newIndex >= max) newIndex = 0;

		setActiveItemIndex(newIndex);
	};

	return (
		<SliderWrapper>
			<AutoPlaySwipeableViews
				enableMouseEvents
				interval={9999915000}
				index={activeIndex}
				onChangeIndex={handleChange}
			>
				{slides.map((slide, i) => (
					<Slide key={`brand-fairs-slide-${i}`}>
						<div className="info">
							<h2>{slide.brandTitle}</h2>
							<FairsListInfo fairs={slide.fairs} />
						</div>
						<div className="card">
							<img src={slide.featuredImage.img} alt="" />
						</div>
					</Slide>
				))}
			</AutoPlaySwipeableViews>
			<div>
				<a href="##" role="button" className="indicator prev" onClick={(e) => handlePrevNext(e, -1)}>
					<Icons icon="chevron-left" />
				</a>
				<a href="##" role="button" className="indicator next" onClick={(e) => handlePrevNext(e, 1)}>
					<Icons icon="chevron-right" />
				</a>
			</div>
		</SliderWrapper>
	);
}

function BrandFairsSlider({ config }) {
	if (!config?.length || !config[0].slides?.length) return null;

	const [activeItemIndex, setActiveItemIndex] = React.useState(0);

	const { title, cta, link, slides } = config[0];

	const handleChangeActiveItem = (e, index) => {
		e.preventDefault();
		setActiveItemIndex(index);
	};

	return (
		<Section>
			<Container>
				<Title isCenter={!link}>
					<h2>{title}</h2>
					{
						link && (
							<InternalLink to={link}>
								<Button primary large>{cta}</Button>
							</InternalLink>
						)
					}
				</Title>
				<SlidesComponent
					slides={slides}
					activeIndex={activeItemIndex}
					setActiveItemIndex={setActiveItemIndex}
				/>
				<Pagination>
					{slides.map((slide, i) => (
						<li
							key={`slider-pages-dot-${i}`}
							className={activeItemIndex === i ? "current" : null}
						>
							<a href="##" role="button" onClick={(e) => handleChangeActiveItem(e, i)}>●</a>
						</li>
					))}
				</Pagination>
			</Container>
		</Section>
	);
}

export default BrandFairsSlider;
