import styled from "styled-components";
import { Section as SectionEx } from "../../sdk/Layout";

export const Section = styled(SectionEx)`
	background-color: ${(props) => props.theme.grayLight};
	color: ${(props) => props.theme.textDefault};
`;

export const Title = styled.div`
	display: flex;
	justify-content: ${({ isCenter }) => (isCenter ? "center" : "space-between")};
	align-items: center;
	margin-bottom: 20px;

	h2 {
		font-size: 28px;
		font-weight: 800;
		text-transform: uppercase;
		text-align: center;
	}

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 20px;
	}
`;

export const SliderWrapper = styled.div`
	position: relative;
	border-left: 0.5px solid ${(props) => props.theme.black};
	padding-left: 30px;
	.indicator {
		position: absolute;
		top: calc(50% - 20px);
		width: 40px;
		height: 40px;
		background-color: ${(props) => props.theme.black};
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			color: ${(props) => props.theme.grayLightAndPrimary};
			width: 18px;
			height: 18px;
		}
	}
	.indicator.prev {
		left: -21px;
		svg {
			padding-right: 2px;
		}
	}
	.indicator.next {
		right: -20px;
		svg {
			padding-left: 2px;
		}
	}
	@media (max-width: 768px) {
		border-left: none;
		padding-left: 0;
		.indicator {
			top: calc(100% + 32px);
			width: 20px;
    		height: 20px;
			// svg {
			// 	width: 16px;
			// 	height: 16px;
			// }
		}
		.indicator.prev {
			left: 8px;
		}
		.indicator.next {
			right: 8px;
		}
	}
`;

export const Slide = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	.info {
		display: flex;
		flex-direction: column;
		min-width: 340px;
		> h2 {
			font-size: 24px;
			margin-bottom: 20px;
			display: flex;
			align-items: center;
			color: ${(props) => props.theme.primary};
			text-transform: uppercase;
		}
	}
	.card {
		img {
			width: 100%;
			max-width: 800px;
			height: 350px; //450px
			object-fit: cover;
		}
	}

	@media (max-width: 768px) {
		flex-direction: column-reverse;
		gap: 20px;
		.info {
			display: flex;
			flex-direction: column;
			align-items: center;
		}
		.card {
			img {
				height: 200px;
			}
		}
	}
`;

export const FairsList = styled.ul`
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 40px;
	li {
		h2 {
			font-size: 18px;
			color: ${(props) => props.theme.textDefault};
			margin-bottom: 20px;
			width: fit-content;
		}
		h3 {
			display: flex;
			align-items: center;
			gap: 6px;
			font-size: 14px;

			span {
				font-weight: 400;
			}

			svg {
				fill: ${(props) => props.theme.textDefault};
				width: 16px;
				height: 16px;
			}
		}
	}
`;

export const Pagination = styled.ul`
	width: 100%;
	display: flex;
	justify-content: center;
	gap: 8px;
	margin-top: 26px;
	li {
		display: inline-block;
		a {
			text-decoration: none;
			color: ${(props) => props.theme.primary100};
			font-size: 28px;
		}
	}
	li.current a {
		color: ${(props) => props.theme.primary};
	}

	// @media (max-width: 768px) {
	// 	padding-left: 0px;
	// }
`;
