import styled from "styled-components";

export const SliderWrapper = styled.div`
	position: relative;
	.indicator {
		position: absolute;
		top: calc(50% - 20px);
	    width: 40px;
    	height: 40px;
		background-color: ${(props) => props.theme.black};
		border-radius: 100%;
		display: flex;
		justify-content: center;
		align-items: center;

		svg {
			color: ${(props) => props.theme.grayLightAndPrimary};
			width: 25px;
			height: 25px;
		}
	}
	.indicator.prev {
		left: -20px;
		svg {
			padding-right: 3px;
		}
	}
	.indicator.next {
		right: -20px;
		svg {
			padding-left: 3px;
		}
	}

	@media (max-width: 768px) {
		.indicator {
			top: calc(100% - 20px);
			width: 20px;
    		height: 20px;
			svg {
				width: 16px;
				height: 16px;
			}
		}
		.indicator.prev {
			left: 8px;
		}
		.indicator.next {
			right: 8px;
		}
	}
`;

export const Pagination = styled.ul`
	width: 100%;
	padding-left: 120px;
	display: flex;
	justify-content: center;
	gap: 8px;
	li {
		display: inline-block;
		a {
			text-decoration: none;
			color: ${(props) => props.theme.primary100};
			font-size: 28px;
		}
	}
	li.current a {
		color: ${(props) => props.theme.primary};
	}

	@media (max-width: 768px) {
		padding-left: 0px;
	}
`;

export const Slide = styled.div`
	position: relative;
	padding-left: 120px;
	overflow: hidden;
	picture {
		img {
			width: 1224px;
			height: 500px;
			object-fit: cover;
		}
	}
	.card {
		position: absolute;
		left: 0px;
		top: 110px;
		margin: auto;
		padding: 20px 40px;
		width: 420px;
		height: 280px;
		background-color: ${(props) => props.theme.primary};
		color: white;
		opacity: 0.9;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 8px;
		h3 {
			font-size: 24px;
			font-weight: 800;
		}
		h4 {
			font-size: 20px;
			font-weight: 600;
		}
		p {
			font-size: 16px;
			font-weight: 400;
			margin-bottom: 20px;
		}
	}
	
	@media (max-width: 768px) {
		padding-left: 0px;
		picture {
			img {
				width: 100%;
				height: auto;
			}
		}
		.card {
			position: static;
			margin-top: -55px;
			padding: 16px;
			width: 100%;
			height: 260px;
		}
	}
`;

export const Title = styled.h2`
	font-size: 28px;
	font-weight: 800;
	color: ${(props) => props.theme.black};
	text-transform: uppercase;
	text-align: center;
	margin-bottom: 16px;
`;

export const SubTitle = styled.h3`
	display: ${(props) => (props.isVisible ? "block" : "none")};
	font-size: 20px;
	font-weight: 400;
	color: ${(props) => props.theme.gray};
	text-align: center;
	margin-bottom: 16px;
`;
