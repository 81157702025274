import React from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

import { Container, Heading } from "../../sdk/Layout";
import {
    Wrapper,
    QuoteL,
    QuoteR,
    Opinions,
    Opinion,
    Text,
    By,
    Pagination,
    Navigation,
} from "./style";
import Icons from "../Icons2";

function Testimonials({ config, _t }) {
    const [activeItemIndex, setActiveItemIndex] = React.useState(0);

    if (config == null || config.length === 0) return null;
    const imagesCount = config.length;
    const changeActiveItem = (activeIndex) => {
        setActiveItemIndex(activeIndex);
    };

    const nextSlide = () => {
        let newActiveItemIndex = activeItemIndex + 1;
        if (newActiveItemIndex >= imagesCount) {
            newActiveItemIndex = 0;
        }

        setActiveItemIndex(newActiveItemIndex);
    };

    const lastSlide = () => {
        let newActiveItemIndex = activeItemIndex - 1;
        if (newActiveItemIndex <= -1) {
            newActiveItemIndex = imagesCount - 1;
        }
        setActiveItemIndex(newActiveItemIndex);
    };

    return (
        <Wrapper
            img={
                config[0].img && config[0].img && config[0].img.img.length > 0
                    ? config[0].img.img[0]
                    : null
            }
        >
            <Container>
                <Heading isCentered>
                    <h3>{_t("ourClientsBelieve")}</h3>
                </Heading>

                <Opinions>
                    <AutoPlaySwipeableViews
                        enableMouseEvents
                        interval={4000}
                        index={activeItemIndex}
                        onChangeIndex={changeActiveItem}
                    >
                        {config.map((index, i) => (
                            <div key={`testimonials-${i}`}>
                                <Opinion>
                                    <Text>
                                        <p>{index.paragraph}</p>
                                    </Text>
                                    <By>{index.author}</By>
                                </Opinion>
                            </div>
                        ))}
                    </AutoPlaySwipeableViews>

                    <Pagination>
                        {config.map((index, i) => (
                            <span
                                key={`options-testimonials-${i}`}
                                className={
                                    i === activeItemIndex ? "current" : ""
                                }
                            ></span>
                        ))}
                    </Pagination>
                </Opinions>
            </Container>

            <Navigation>
                <span onClick={lastSlide}>
                    <Icons icon="chevron-left"></Icons>
                </span>
                <span onClick={nextSlide}>
                    <Icons icon="chevron-right"></Icons>
                </span>
            </Navigation>
        </Wrapper>
    );
}

export default React.memo(Testimonials);
